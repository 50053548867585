<template>
  <div style="
          background-color: #171726;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        ">
    <nav-bar class="title_top" :icon="'img/backw.png'" :textColor="'#ffffff'" :backColor="'none'" />
    <div>
      <div class="tap_item" @click="toIncomeRecord">
        <span>收益账单</span>
        <img src="img/myIncome/next.png" alt="" />
      </div>
      <div class="tap_item" @click="toWithdrawRecord">
        <span>提现记录</span>
        <img src="img/myIncome/next.png" alt="" />
      </div>
      <div class="tap_item" @click="toExchaneRecord">
        <span>兑换记录</span>
        <img src="img/myIncome/next.png" alt="" />
      </div>
      <div class="tap_item" @click="toGoldRecord">
        <span>金币收支</span>
        <img src="img/myIncome/next.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/base.css";
import "@/assets/css/index.css";
import NavBar from "@/components/navBar.vue";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      accessToken: "",
    };
  },
  created() {
    this.accessToken = localStorage.getItem("access_token");
  },
  methods: {
    toIncomeRecord() {
      this.$router.push({
        path: "/incomeRecord?access_token=" + this.accessToken + "&from=h5",
      });
    },
    toWithdrawRecord() {
      this.$router.push({
        path: "/withdrawRecord?access_token=" + this.accessToken + "&from=h5",
      });
    },
    toExchaneRecord() {
      this.$router.push({
        path: "/exchangeRecord?access_token=" + this.accessToken + "&from=h5",
      });
    },
    toGoldRecord() {
      this.$router.push({
        path: "/goldRecord?access_token=" + this.accessToken + "&from=h5",
      });
    }
  },
};
</script>
<style scoped>
.tap_item {
  padding: 0 24px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 114px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  background-color: rgba(255, 255, 255, 0.04);
}

.tap_item span {
  color: #ffffff;
  font-size: 30px;
}

.tap_item img {
  width: 25px;
  height: 25px;
}
</style>
